import axios from '@/plugins/axios'

export const getTotalCampaigns = async (_, params) => {
  try {
    const { data } = await axios.get('/admin-dashboard/campaign-totals', { params })

    return data
  } catch (err) {
    return err
  }
}

export const getTotalOrders = async (_, params) => {
  try {
    const { data } = await axios.get('/admin-dashboard/total-orders', { params })

    return data
  } catch (err) {
    return err
  }
}

export const getActiveUsers = async () => {
  try {
    const { data } = await axios.get('/admin-dashboard/active-users')

    return data
  } catch (err) {
    return err
  }
}

export const getNewUsers = async (_, params) => {
  try {
    const { data } = await axios.get('/admin-dashboard/new-users', { params })

    return data
  } catch (err) {
    return err
  }
}

export const getUserTotalOrders = async (_, params) => {
  try {
    const { data } = await axios.get('/admin-dashboard/user-total-orders', { params })

    return data
  } catch (err) {
    return err
  }
}
