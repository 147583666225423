import Vue from 'vue'
import Vuex from 'vuex'
import affiliateCustom from './affiliateCustom'
import affiliateReport from './affiliateReport'
import affiliates from './affiliates'
import adminDashboard from './adminDashboard'
import app from './app'
import auth from './auth'
import controlFile from './file'
import reportServices from './reportServices'
import reportSmsTemplates from './reportSmsTemplates'
import reportVoice from './reportVoice'
import reportDetail from './reportDetail'
import services from './services'
import serviceSms from './serviceSms'
import servicePlans from './servicePlans'
import serviceVoice from './serviceVoice'
import smsTemplates from './smsTemplates'
import serviceSanitizer from './serviceSanitizer'
import user from './user'
import userAccount from './userAccount'
import wallet from './wallet'
import walletExtract from './walletExtract'
import customerLevels from './customerLevels'
import taxes from './taxes'
import customerTaxes from './customerTaxes'
import order from './order'
import webhook from './webhook'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    affiliateCustom,
    affiliateReport,
    affiliates,
    adminDashboard,
    app,
    auth,
    controlFile,
    reportServices,
    reportSmsTemplates,
    reportVoice,
    reportDetail,
    services,
    serviceSms,
    serviceVoice,
    smsTemplates,
    serviceSanitizer,
    servicePlans,
    user,
    userAccount,
    wallet,
    walletExtract,
    customerLevels,
    taxes,
    customerTaxes,
    order,
    webhook,
  },
  plugins: [],
  strict: process.env.NODE_ENV !== 'production',
})
